@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Vesper+Libre:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey&family=Lobster&family=Roboto:wght@500&family=Vesper+Libre:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey&family=Lobster&family=Noto+Serif+Display:wght@800&family=Roboto:wght@500&family=Vesper+Libre:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey&family=Lobster&family=Noto+Serif+Display:wght@800&family=Playfair+Display:wght@600&family=Roboto:wght@500&family=Vesper+Libre:wght@900&family=Zilla+Slab:wght@300&display=swap');

.body {
  font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
}


.top-navbar {
  /* background-color: #d6d6d6; */
  opacity: 3;
  font-size: 16px;
}

#contact-text {
  font-family: 'Zilla Slab', serif;
}

.mission-content {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .mission-content {
    font-size: 16px;
  }
}

#initial {
  background-color: #5b035b;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 100px;
}


#first-block {
  margin-top: 50px;
  text-align: left;
}

.discover-more-button {
  border: none;
  padding-inline: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.login-button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid rgb(37, 37, 37);
}

.login-button:hover {
  background-color: #565656;
  color: #ffffff;
  transition: 0.3s ease;
}

.nav-item {
  padding: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;

}

.navbar-nav {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.nav-links {
  color: rgb(255, 255, 255);
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.nav-links:hover {
  color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 10.5;
}

.nav-icons {
  font-size: 26px;
  margin-top: 5px;
  margin-left: 50px;
}

.ad-wrapper {
  background-color: #ffe1b0;
}

.profile-toggle-card {
  width: 150px;
  height: auto;
  float: right;
  position: absolute;
  right: 0;
  top: 5rem;
  z-index: 300;
}

.toggle-items {
  color: #202020;
  text-decoration: none;
}

.toggle-items:hover {
  text-decoration: underline;
  transition: 0.5s ease-in-out;
  color: #202020;
}

.stepIcon {
  height: 40px;
  width: 40px;
  border: 1px solid #202020;
  text-align: center;
  border-radius: 50%;
  padding-top: 8px;
}

.stepIcon-completed {
  height: 40px;
  width: 40px;
  background-color: rgb(157, 162, 255);
  border: 1px solid #b8b8b8;
  text-align: center;
  border-radius: 50%;
  /* padding-top: 5px; */
  color: #ffffff;
  font-size: 22px;
}

.stepperDividerLine {
  border-top: 4px solid #202020;
  flex-grow: 1;
  margin-top: 20px;
}

.stepperDividerLine-completed {
  border-top: 4px solid rgb(13, 25, 255);
  flex-grow: 1;
  margin-top: 20px;
}

.icons {
  margin-right: 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

#ads_1 {
  margin-top: 30px;
}

#ads_2 {
  margin-top: 20px;
}

#contactus-container {
  margin-top: 130px;
}

#written-content {
  align-content: center;
}

#contact-text {
  margin-top: 20px;
  text-align: center;
  color: white;
  padding: 0 50px 0 50px;
  border-radius: 20px;
  background-color: #222222;
}

#maps {
  margin-top: 100px;

}

#contact-details {
  margin-top: 70px;
}

#contact-us-letter {
  font-size: 38px;
  margin-top: 50px;
  color: white;
  font-family: 'Lobster', cursive;
  font-family: 'Vesper Libre', serif;
}

#About-us-content {
  display: flex;
  justify-content: space-between;
  color: white;
  text-align: left;
  font-size: 18px;
}

#headline {
  color: white;
  font-family: 'Lobster', cursive;
  font-family: 'Vesper Libre', serif;
  font-size: 52px;
  margin-top: 60px;
}

@media (max-width: 767px) {
  #headline {
    font-size: 32px;
    /* Adjust this value as needed */
  }
}


#first-headline {
  font-size: 48px;
  color: #5b035b;
  font-family: 'Lobster', cursive;
  font-family: 'Vesper Libre', serif;
  font-weight: 700;
  text-align: left;
}

@media (max-width: 767px) {
  #first-headline {
    font-size: 26px;
    /* Adjust this value as needed */
  }

  #block-content {
    font-size: 12px;
  }

  .explore {
    margin-top: 30px;
  }

}

#block-content {
  font-size: 20px;
  text-align: left;
  color: #4e4e4e;
}

#Third-block {
  margin-top: 150px;
  text-align: left;
}

#our-projects {
  text-align: center;
  color: #5b035b;
  font-family: 'Lobster', cursive;
  font-family: 'Vesper Libre', serif;
  font-size: 48px;
  font-weight: bolder;
  margin-bottom: 60px;
  text-decoration: underline;
}

#spec-container {
  margin: 300px 0 300px 0;
}

#spec-container-2 {
  margin: 100px 0 300px 0;
}

@media (max-width: 767px) {
  #our-projects {
    font-size: 32px;
  }

  #spec-container {
    margin: 100px 0 100px 0;
  }
}

#aminities {
  color: #d2ae6d;
  font-family: 'Playfair Display', serif;
  font-size: 58px;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  #aminities {
    font-size: 26px;
  }
}

#aminity-head {
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  margin-right: 15px;
}

#the-logo {
  width: 200px;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  #aminity-head {
    font-size: 14px;
    text-decoration: underline;
    margin-right: 15px;
  }

  #the-logo {
    width: 200px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  #footer-col-style {
    margin: 30px 0 30px 0;
  }
}

#Aminity-list {
  font-size: 16px;
  color: #3e3e3e;
  font-weight: bold;
}

@media (max-width: 767px) {
  #Aminity-list {
    font-size: 14px;
  }
}

#nav-bar:hover {
  background-color: #1a1a1a;
  transition: 0.5s ease-in-out;
  opacity: 0.8;
}

@media (max-width: 767px) {
  #nav-bar {
    height: 0px;
  }

  #collapsibleNavbar {
    background-color: #212836;
  }


}

@media not all and (max-height: 899px) and (min-resolution:.1dpi) {
  #nav-links {
    /* background-color: #212836; */
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}



@media (max-width: 767px) {
  #about-container {
    display: flex;
    flex-direction: column;
  }

}

@media (max-width: 767px) {
  #left {
    width: 100% !important;
    margin: 5px;
  }

  #right {
    width: 100% !important;
    margin-left: 0px !important;
    margin: 5px;
    height: auto !important;
  }

  #myBtn {
    display: block !important;
}

#whatsappIcon {
  font-size: 20px;
}
}

#myBtn {
  display: block;
  position: fixed;
  bottom: 30px;
  right: 5%;
  z-index: 100;
  border: none;
  outline: none;
  background-color: rgb(32, 152, 16);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50px;
}

#whatsappIcon {
  font-size: 50px;
}

#car-img-1 {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  width: 100%;
  -webkit-filter: brightness(40%);
}

#car-img-3 {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  width: 100%;
  -webkit-filter: brightness(40%);
}

#car-img-2 {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  width: 100%;
  -webkit-filter: brightness(40%);
}


@media (max-width: 767px) {
  #car-img-1 {
    height: 100vh;
    width: 300% !important;
    background-position: center;
  }

  #car-img-2 {
    height: 100vh;
    width: 300% !important;
    background-position: center;
  }

  #car-img-3 {
    width: 100%;
    height: 100vh;
    background-size: cover;
    -webkit-filter: brightness(30%);
  }
}

#banner-image {
  width: 100%;
  height: 100vh;
  background-image: url("../src//images/ProductImages/carousel_1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
}

#carousal-main {
  font-size: 92px;
  padding-bottom: 30px;
  font-family: 'Noto Serif Display', serif;
  color: #ffffff;
}

#carousal-main-2 {
  font-size: 62px;
  padding-bottom: 30px;
  font-family: 'Noto Serif Display', serif;
  color: #ffffff;
}

@media (max-width: 767px) {
  #carousal-main {
    font-size: 28px;
    padding-bottom: 3px;
    font-family: 'Noto Serif Display', serif;
    color: #ffffff;
  }

  #carousal-main-2 {
    font-size: 28px;
    padding-bottom: 30px;
    font-family: 'Noto Serif Display', serif;
    color: #ffffff;
  }

  #carousal-subtext {
    font-size: 14px !important;
    padding-bottom: 3px;
    font-family: 'Noto Serif Display', serif;
    color: #ffffff;
    font-weight: lighter;
  }

  #carousal-subtext-2 {
    font-size: 14px !important;
    padding-bottom: 10px;
    font-family: 'Noto Serif Display', serif;
    color: #ffffff;
    font-weight: lighter;
  }

  .carousal-button {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    background-color: #5b035b;
    color: white;
    border: 0;
    /* margin-bottom: 250px; */
    font-size: 18px;
  }

  #navbar-brand {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  #logo-image {
    width: 40px;
  }

  #toggles-icon {
    height: 40px;
  }

  #contact-us-letter {
    font-size: 28px;
  }

}

#hero {
  top: 40%;
}

#carousal-subtext {
  font-size: 28px;
  padding-bottom: 30px;
  font-family: 'Noto Serif Display', serif;
  color: #ffffff;
  font-weight: lighter;
}

#carousal-subtext-2 {
  font-size: 24px;
  padding-bottom: 30px;
  font-family: 'Noto Serif Display', serif;
  color: #ffffff;
  font-weight: lighter;
}

.carousal-button {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  background-color: #5b035b;
  color: white;
  border: 0;
  font-size: 18px;
}



.carousal-button:hover {
  background-color: white;
  color: black;
  transition: 0.5s ease;
}

#toggles-icon {
  background-color: white !important;
  border: 0px solid white;
}

.navbar-toggler {
  color: white;
}


#round-img {
  width: 200px;
  height: 200px;
  margin-left: 18%;
  border-radius: 50%;
  margin-bottom: -100px;
  background-color: #5b035b;
  border: 0px;
}

#content-card {
  height: 400px;
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  padding: 30px;
  font-size: 16px;
  /* font-family: 'Noto Serif Display'; */
  font-family: serif;
  border-radius: 10px;
}

#icon {
  color: white;
  height: 70px;
  width: 70px;
  margin: 60px;
}

#building-images {
  width: 100%;
  height: auto;
  transition: transform .5s;
}

#building-images:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.05); 
}

#about-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

#about-image-css {
  border-radius: 10px;
}

#left {
  width: 50%;
  height: 100%;
}

#right {
  width: 50%;
  height: auto;
  background-color: #202020;
  margin-left: -150px;
  border-radius: 10px;
}

#overlap-content {
  padding: 50px;
}

#prarambh-text {
  font-family: 'Noto Serif Display', serif;
  color: #5b035b;
  font-size: 82px;
  text-decoration: underline;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  #prarambh-text {
    font-family: 'Noto Serif Display', serif;
    color: #5b035b;
    font-size: 32px;
    text-decoration: underline;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

#qr-code-img {
  height: 200px;
  width: auto;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}



#service-title {
  text-align: center;
  font-size: 26px;
  color: #2e2e2e;
  font-weight: 600;
}

.product-card {
  transition: all 0.3s;
  height: 300px;
}

.card-price-body {
  height: 90px;
}

.by-brand {
  text-decoration: underline;
}

.product-card:hover {
  box-shadow: 10px 10px 10px 0 grey;
  transition: all 0.3s;
  transform: scale(1.15);
}

.icon-span {
  margin-left: -30px;
  vertical-align: top;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  padding: 3px;
  font-weight: 900;
}

.imagerow {
  display: flex;
}

.imagecolumn {
  flex: 60%;
  padding: 5px;
}

.assurance-icons {
  font-size: 30px;
  color: rgb(60, 125, 131);
}

.color-pick {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 30px;
}

.color-pick li {
  height: 32px;
  width: 32px;
  background-color: blueviolet;
  border-radius: 50%;
  border: 1 solid black;
}

.color-pick li:hover {
  border: 5 solid rgb(255, 6, 226);
}


.productPage-cartButton {
  width: 100%;
  height: 50px;
  background-color: white;
  border-radius: 10px;
}

.productPage-cartButton:hover {
  background-color: rgb(71, 71, 71);
  color: white;
  transition: 0.4s ease;
}

.page-bg {
  background-image: linear-gradient(45deg, rgb(234, 234, 234), rgb(255, 255, 255));
  min-height: 100vh;
}

.explore {
  width: 50%;
  height: 50px;
  background-color: #5b035b;
  color: white;
  border-radius: 5px;
  margin-top: 60px;
  border: 0px;
}

@media (max-width: 767px) {
  .explore {
    margin-top: 30px;
  }
}

.explore:hover {
  background-color: rgb(71, 71, 71);
  color: white;
  transition: 0.4s ease;
  border: 0px;
}

.knowMore {
  width: 40%;
  height: 50px;
  background-color: #5b035b;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  margin-top: 30px;
  border: 0px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .knowMore {
    font-size: 14px;
    /* Adjust this value as needed */
  }
}

.knowMore:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(73, 73, 73);
  font-weight: 500;
  transition: 0.4s ease;
  border: 0px;
}



.deliver-button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgb(224, 224, 224);
}

.deliver-button:hover {
  background-color: rgb(71, 71, 71);
  color: white;
  transition: 0.4s ease;
}

#footer {
  background-color: rgb(19, 19, 19);
  color: white;
}

.cartTextVertical {
  vertical-align: middle;
}

.cart-counter-button {
  height: 50px;
  width: 50px;
  border-color: #ffffff;
  background-color: white;
  border-width: thin;
  border-radius: 5px;
  color: rgb(23, 23, 23);
  font-size: 18px;
}

.add-product-icons {
  height: 30px;
  width: 30px;
  border: 1px solid rgb(185, 185, 185);
  text-align: center;
  color: #202020;
  border-radius: 10px;
}

.add-product-icons:hover {
  background-color: #8d8d8d;
  color: #ffffff;
}

.admin-nav {
  background-color: #e7e7e7;

}


.socialMedia-icons {
  display: inline-block;
}

.review-card {
  height: 100px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.show-card {
  height: 300px;
  width: 600px;
  background-color: rgb(70, 70, 255);
  color: white;
  border-radius: 10px;
}

/* PREVIOUS PROJECTS PAGE CSS */

#previous-projects-text {
  font-family: 'Noto Serif Display', serif;
  color: #5b035b;
  font-size: 64px;
  text-decoration: underline;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

#upcoming-projects-text {
  font-family: 'Noto Serif Display', serif;
  color: #5b035b;
  font-size: 52px;
  text-decoration: underline;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

#projects-headline {
  font-size: 42px;
  color: #d2ae6d;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  text-align: center;
}

#subtext {
  color: #544d4d;
  font-size: 34px;
}

#project-content {
  font-size: 16px;
  text-align: left;
  color: #6d6b6b;
  padding: 15px;
}

#project-block {
  margin-top: 150px;
  text-align: left;
}

.brochure {
  width: 50%;
  height: 50px;
  background-color: #d2ae6d;
  color: white;
  border-radius: 5px;
  margin: 60px 0 60px 0;
  border: 0px;
}

.brochure:hover {
  background-color: #383535;
  color: white;
  transition: 0.6s ease;
}

@media (max-width: 767px) {
  #projects-headline {
    font-size: 32px;
    /* Adjust this value as needed */
  }

  #previous-projects-text {
    font-size: 32px !important;
  }

  #upcoming-projects-text {
    font-size: 32px !important;
  }

  #project-block {
    margin-top: 50px;
    text-align: left;
  }

  #subtext {
    color: #544d4d;
    font-size: 22px;
  }

  .brochure {
    width: 50%;
    height: 50px;
    background-color: #d2ae6d;
    color: white;
    border-radius: 5px;
    margin: 30px 0 30px 0;
    align-items: center;
    border: 0px;
  }
}

#specifications-card {
  background-color: #d2ae6d;
  border-radius: 0 30px 0 30px;
  padding: 20px;
  color: #373333;
  font-family: Arial, Helvetica, sans-serif;
}

#aminity-heading {
  color: white;
  font-size: 28px;
  font-family: 'Playfair Display', serif;
  text-decoration: underline;
}

#heading {
  text-decoration: underline;
  color: #ffffff;
}

#project-specifications-text {
  color: rgb(64, 56, 56);
  font-size: 28px;
  font-family: 'Playfair Display', serif;
  text-decoration: underline;
}